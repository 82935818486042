export const environment = {
  name: 'development',
  version: 'develop',
  production: false,
  sentry: {
    dsn: 'https://8e8579440b241f7beca527816cf959a3@o4506207716114432.ingest.sentry.io/4506581323153408',
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    sendClientReports: false,
    showDialog: true,
  },
  admin: {
    url: 'https://dev-admin.freddy.ovh',
  },
  firebase: {
    region: 'europe-west1',
    projectId: 'freddy-931ae',
    appId: '1:876816755465:web:48557f5936f2de9cae01d8',
    storageBucket: 'freddy-931ae.appspot.com',
    apiKey: 'AIzaSyAuyxHTOLb4KTVHvAVzjK5agcMFmkYtGlI',
    authDomain: 'dev-game.freddy.ovh',
    messagingSenderId: '876816755465',
    databaseURL:
      'https://freddy-931ae-default-rtdb.europe-west1.firebasedatabase.app/',
    measurementId: 'G-MCL3RM4R6B',
    recaptcha: '6LcIzlcpAAAAAJ40uUgBrYoHcRjn2QJTY8wr_V1Q',
  },
  mapbox: {
    token:
      'pk.eyJ1IjoiYm9rem9yIiwiYSI6ImNsZTRvenBxbzA1M3k0MXFkb2ZzM2NxbnYifQ.9Mac1d54adqHiqTp000cHg',
    geocoder: {
      countriesActivated: 'fr,nl,be',
    },
    style: 'mapbox://styles/bokzor/clzzip5s8005v01phbvfvddax',
    preload: {
      enabled: true,
    },
    defaultZoom: 16,
    minZoom: 13,
    maxZoom: 19,
  },
};
